$paletteSize: 342px;

%block-border {
  border: 4px solid rgba($w, 0);
}

.palette-card-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 73px;
  gap: 10px 10px;
  grid-template-areas: 'neautrals mains' 'accents accents';
  min-height: calc(#{$paletteSize} - 20px);
}

.colour-block {
  display: block;
  height: 100%;
}

.palette-block {
  transition: opacity 150ms ease-in;

  &:hover {
    opacity: 0.5;
  }
}

.neautrals {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-template-areas: 'n1 n2';
  grid-area: neautrals;
}

// Neautral areas
@for $i from 1 through 2 {
  .n#{$i} {
    grid-area: n#{$i};
    @extend %block-border;
  }
}

.mains {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px 0px;
  grid-template-areas: 'm1' 'm2' 'm3';
  grid-area: mains;
}

// Main areas
@for $i from 1 through 3 {
  .m#{$i} {
    grid-area: m#{$i};
    @extend %block-border;
  }
}

.accents {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-template-areas: 'a1 a2 a3 a4';
  grid-area: accents;
}

// Accents areas
@for $i from 1 through 34 {
  .a#{$i} {
    grid-area: a#{$i};
    @extend %block-border;
  }
}

.palette-card {
  @include boxShadow(secondary);
  background-color: $w;
  border-radius: 14px;
  width: $paletteSize;
  padding: 10px;

  &-title {
    font-weight: 500;
    margin: 5px 0 0;
    color: $tdDGrey;
  }

  &-block--selected {
    @include boxShadow(primary);
    border: 4px solid rgba($w, 0.25);
    transform: scale(1.07);
    transition: transform ease 300ms, box-shadow ease 300ms 100ms, border ease-in 300ms;
  }

  &--disabled {
    @extend %disabled;
  }
}
