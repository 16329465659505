// Boostratp theming
// Variables override
$primary: $tdPink;
$secondary: $tdBlk;
$success: $tdGreen;
$info: $tdBlue;
$warning: $tdPink3;
$danger: $tdRed;
$light: $tdLGrey;
$dark: $tdDGrey;

$colors: (
  'blue': $tdBlue,
  'pink': $tdPink,
  'red': $tdRed,
  'green': $tdGreen,
  'gray': $tdGrey,
);

$border-radius: 4px;

// Font
$font-family-base: $primary-font;

// Input button
$input-btn-font-family: $primary-font;
$input-btn-padding-y: 1.2rem;
$input-btn-padding-x: 1.2rem;

// Input
$input-padding-y: 0.75rem;
$input-padding-x: 0.75rem;

// Button
$btn-padding-y: 10px;
$btn-padding-x: 25px;
$btn-font-weight: 600;
$btn-font-size: 0.875rem;

// Links
$link-hover-decoration: none;

// Nav
$nav-tabs-border-radius: 6px;
$nav-tabs-link-active-color: $tdPink;
