%disabled {
  pointer-events: none;
  opacity: 0.5 !important;
}

%bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
