@import 'partials/partials';

.section-items {
  flex: 1;
  height: calc(100vh - 63px);
  overflow-y: auto;
  position: relative;
}

.section-container {
  // Hide scrollbar while editor is animated in
  overflow-x: hidden;

  &-heading {
    @include flexBox(flex, space-between, center);
    position: sticky;
    top: 0;
    background: $offWhite;
    backdrop-filter: blur(8px);
    opacity: 0.9;
    z-index: 1;
  }
}
