@import '../partials/theme';

.form-control {
  box-sizing: border-box;
}

.form-control-textarea {
  font-size: 1rem;

  &.form-control {
    @include borderRadius(primary);
    color: $tdDGrey;
    max-height: 200px;

    &:focus {
      border-color: $tdLightBlue;
      color: $tdDGrey;
      box-shadow: none;
    }
    &::placeholder {
      color: $tdGrey;
    }
  }
}

.character-count {
  margin-left: auto;
  font-size: 0.7rem;
  font-weight: 500;
  transition: color 150ms ease-in;
}

.input {
  &-colour,
  &-colour:focus {
    @include borderRadius(primary);
    border: 1px solid $tdLGrey;
    color: $tdDGrey;
    text-align: center;
    max-width: 120px;
    padding: 15px 25px;
    transition: border 300ms ease;
    text-transform: uppercase;

    &:focus {
      border-color: $tdLightBlue;
    }
  }

  &-loader {
    position: absolute;
    top: 1rem;
    right: $input-padding-x;
  }
}

.form-control-select {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: calc((100% - 11.429px) / 2);
    right: 1.2rem;
    width: 20px;
    height: 11.429px;
    background-image: url('../../assets/svg/ui-icons/chevron-down.svg');
    background-repeat: no-repeat;
    pointer-events: none;
  }

  & select {
    appearance: none;
    color: $tdDGrey;

    &:disabled {
      color: blue;
    }

    option[value=''] {
      color: $tdGrey;
    }
  }
}

.file-uploader {
  cursor: pointer;
  background-color: rgba($tdBlk, 0.95);
}
