.loader {
  width: 8vw;
  animation: loader 7s ease infinite;
}

.loader-text {
  animation: loaderText 1.75s ease infinite;
}

@keyframes loader {
  0% {
    filter: hue-rotate(0deg) brightness(1);
  }
  50% {
    filter: hue-rotate(360deg) brightness(3);
  }
}

@keyframes loaderText {
  0% {
    color: $tdBlk;
  }
  50% {
    color: $tdDGrey;
  }
}

@keyframes loaderTextColoured {
  0% {
    color: $tdPink;
  }
  50% {
    color: $tdBlue;
  }
}
