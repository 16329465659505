@import 'partials/partials';
@import 'layout/lib-dir';
@import 'typography/typography';
@import 'pages/lib_dir';
@import 'components/lib_dir';

// Bootstrap theming
@import 'partials/theme';

// Bootstrap handpicked imports
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/forms';
@import '../../../node_modules/bootstrap/scss/alert';
@import '../../../node_modules/bootstrap/scss/modal';
@import '../../../node_modules/bootstrap/scss/buttons';
@import '../../../node_modules/bootstrap/scss/badge';
@import '../../../node_modules/bootstrap/scss/tooltip';
@import '../../../node_modules/bootstrap/scss/nav';
@import '../../../node_modules/bootstrap/scss/list-group';
@import '../../../node_modules/bootstrap/scss/utilities';
@import '../../../node_modules/bootstrap/scss/spinners';
@import '../../../node_modules/bootstrap/scss/close';
@import '../../../node_modules/bootstrap/scss/type';
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/images';
@import '../../../node_modules/bootstrap/scss/transitions';

// :root {
//   --primary-colour: #{$tdPink};
// }

body {
  background-color: $w;
  @include fontW(400, $primary-font);
  // color: var(--primary-colour);
  color: $tdBlk;
}
