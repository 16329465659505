@import 'variables';
@import 'mixins';

.flex {
  display: flex;

  &-col {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-one {
    flex: 1;
  }

  &--col-m {
    @include MQ(S) {
      flex-direction: column;
    }
    @include MQ(XS) {
      flex-direction: column;
    }
  }
}

// Justify content
.jc {
  &-space-between {
    justify-content: space-between;
  }
  &-center {
    justify-content: center;
  }
  &-flex-start {
    justify-content: flex-start;
  }
  &-flex-end {
    justify-content: flex-end;
  }
  &-space-around {
    justify-content: space-around;
  }
  &-space-evenly {
    justify-content: space-evenly;
  }
}

// Align Items
.ai-flex-start {
  align-items: flex-start;
}
.ai-flex-end {
  align-items: flex-end;
}
.ai-center {
  align-items: center;
}

// Overflow
.overflow {
  &-hidden {
    overflow: hidden;
  }
}

// Spacing
$values: (
  5: 5,
  15: 15,
  30: 30,
  50: 50,
  75: 75,
  100: 100,
  200: 200,
);

@each $key, $val in $values {
  // Margin
  .m-#{$key} {
    margin: $val + px;
  }

  .mt-#{$key} {
    margin-top: $val + px;
  }

  .mr-#{$key} {
    margin-right: $val + px;
  }

  .ml-#{$key} {
    margin-left: $val + px;
  }

  .mb-#{$key} {
    margin-bottom: $val + px;
  }

  .offset-top-#{$key} {
    margin-top: -$val + px;
    padding-top: $val + px;
  }

  // Padding
  .p-#{$key} {
    padding: $val + px;
    box-sizing: border-box;
  }

  .pt-#{$key} {
    padding-top: $val + px;
  }

  .pr-#{$key} {
    padding-right: $val + px;
  }

  .pl-#{$key} {
    padding-left: $val + px;
  }

  .pb-#{$key} {
    padding-bottom: $val + px;
  }
}

// Position
.position {
  // &-fixed {
  //   position: fixed;
  // }
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }
}

// Typography
.h3 {
  @include fontW(700, $primary-font);
  @include fontSize(l);
  color: $tdDGrey;
  line-height: 28px;
  margin-bottom: 7px;
}

.h4 {
  @include fontW(900, $primary-font);
  @include fontSize(s);
  color: $w;
  line-height: 24px;
}

.p {
  @include fontW(400, $primary-font);
  @include fontSize(s);
  color: $tdDGrey;
  line-height: 24px;

  &-xs {
    @include fontSize(xs);
  }
}

.txt {
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-xs {
    @include fontSize(xs);
  }

  &-capitalize {
    text-transform: capitalize;
  }
}

.bold {
  @include fontW(700, $primary-font);
}

// Box shadow
.bs {
  &-primary {
    @include boxShadow(primary);
  }

  &-secondary {
    @include boxShadow(secondary);
  }
}

// Box radius
.br {
  &-primary {
    @include borderRadius(primary);
  }
}

// Colours
$colours: (
  'white': $w,
  'td-black': $tdBlk,
  'td-pink': $tdPink,
  'td-red': $tdRed,
  'td-grey': $tdGrey,
  'td-dgrey': $tdDGrey,
  'td-offwhite': $tdOffWhite,
  'offwhite': $offWhite,
);

@each $colourName, $colourVal in $colours {
  .bg-#{$colourName} {
    background-color: $colourVal;
  }

  .txt-#{$colourName} {
    color: $colourVal;
  }
}

.bg-gradient {
  background: linear-gradient(135deg, $tdPink, $tdRed);
}

// Cursor
.cursor-pointer {
  cursor: pointer;
}

// Spacers
.horizontal-line {
  height: 1px;
  flex: 1;
}
