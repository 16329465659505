.header {
  @include flexBox(flex, space-between, center);
  @include boxShadow(primary);
  background-color: $w;
  padding: 0px 50px;
  position: fixed;
  width: 100%;
  z-index: 5;

  & + .section-container {
    padding-top: 63px;
  }
}

.navigation-items {
  @include flexBox(flex, space-between, center);
  margin: 0;
}

.navigation-item {
  @include borderRadius(primary);
  margin: 6px 0;
  display: block;
  padding: 12px 64px;
  transition: background-color ease 300ms;
  cursor: pointer;

  &:hover {
    background-color: $offWhite;
    transition: background-color ease 300ms;

    .ui-icon {
      path {
        fill: $tdPink;
      }
    }
  }

  &.active {
    background-color: transparent;
    position: relative;

    .ui-icon {
      path {
        fill: $tdPink;
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-image: linear-gradient(135deg, $tdPink, $tdRed);
      bottom: -6px;
      left: 0;
      animation: menuItemActive 900ms cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
    }
  }
}

@keyframes menuItemActive {
  from {
    clip-path: circle(10% at 50% 100%);
  }
  to {
    clip-path: circle(100% at 50% 100%);
  }
}

.navigation-list-item {
  position: relative;
}

.navigation-avatar {
  @extend %bg-cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;

  &-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    transition: background-color ease 300ms;

    &:hover {
      background-color: $offWhite;
    }

    &.active {
      background-color: $offWhite;
    }
  }
}

.utility-navigation {
  @include flexBox(flex, center, center);
}

.utility-navigation-item {
  @include flexBox(flex, center, center);
  padding: 12px;
  background-color: transparent;
  border-radius: 100%;
  width: 50px;
  transition: background-color ease 300ms;

  &:hover {
    background-color: $offWhite;
    transition: background-color ease 300ms;
  }
}
