.editor {
  @include boxShadow(primary-x);
  overflow-y: auto;
  padding: 30px 50px;
  width: 33.3vw;
  height: calc(100vh - 63px);
  z-index: 1;
  animation: editorIn ease 300ms forwards;

  &-label {
    font-size: 0.9rem;
  }

  &-section-label {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    color: $tdPink;
  }

  &-title,
  &-title:focus {
    font-size: 1.25rem;
    border: none;
    border-bottom: 1px solid $tdLGrey;
    padding: 0 15px 7px;

    &:focus {
      border-color: $tdLightBlue;
    }
  }

  &-alert {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 30px;
    width: calc(100% - 100px);
    // right: 25px;
    opacity: 0.9;
    backdrop-filter: blur(8px);
    z-index: 1021; // 1 above position-sticky from Bootstrap

    & .alert {
      margin-bottom: 0;
    }
  }
}

.hue-horizontal {
  border-radius: 16px !important;

  & div {
    transition: all 150ms ease, box-shadow 150ms ease;
  }
}

.editor-saturation {
  @include borderRadius(primary);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 31vh;

  &--small {
    height: 56px;
  }
}

// this adds too much delay when draggin the picker around
// ideally only apply the style when the circle isn't dragged
// .saturation-white {
//   & :not(.saturation-black) {
//     transition: all 150ms ease-in;
//   }
// }

.editor-pointer {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  box-shadow: inset 0px 0px 0px 2px $w;
  transform: translate(-8px, -8px);
  transition: box-shadow ease 300ms;

  &--light {
    box-shadow: inset 0px 0px 0px 2px $b;
  }
}

.editor-image {
  &-uploader {
    @include flexBox(flex, center, center, column);
    height: calc(33.3vw - 100px);
    width: calc(33.3vw - 100px);
    background-color: $tdOffWhite;
    border: 4px dashed $tdPink1;
    text-align: center;
    cursor: pointer;
  }

  &-preview {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  &-upload-icon {
    width: 7vw;
  }
}

.editor-uploader {
  &-icon {
    font-size: 4rem;
    line-height: 1;
    animation: cloudSlideUp 3.5s ease-in-out forwards;
  }

  &-text {
    animation: loaderTextColoured 1.75s ease-in-out infinite;
  }
}

@keyframes editorIn {
  from {
    opacity: 0;
    transform: translateX(33.3vw);
  }
  to {
    opacity: 1;
    transform: translateX(0vw);
  }
}

@keyframes cloudSlideUp {
  from {
    transform: translateY(0vw);
  }
  to {
    transform: translateY(-9vw);
  }
}
