.login-bg {
  z-index: -1;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background: {
    image: url('../../assets/svg/section-bg.svg');
    repeat: no-repeat;
    size: cover;
    position: center;
  }

  img {
    height: 100%;
    width: auto;
  }
}
