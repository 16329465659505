.tabs-container {
  @include borderRadius(primary);
  @include boxShadow(secondary);
  background-color: $w;
  height: 100vh;
  overflow-y: auto;
}

.tabs-navigator {
  position: sticky;
  top: 0;
  background-color: rgba($w, 0.95);
  backdrop-filter: blur(8px);

  .nav-link {
    color: $tdDGrey;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.tab-content-inner {
  @include flexBox(flex, flex-start, center);
  flex-wrap: wrap;
}
