.wadrobe-item {
  transition: opacity 150ms ease-in, border ease-in 150ms;
  border: 0px solid rgba($tdDGrey, 0);
  cursor: pointer;
  opacity: 1;

  &-btn {
    top: 5px;
    right: 5px;
  }

  &--selected {
    transition: border ease-in 150ms;
    border: 4px solid $tdBlue;

    &:hover {
      opacity: 1 !important;
      border: 4px solid $tdLightBlue;
    }
  }

  &--disabled {
    @extend %disabled;
  }

  &:hover {
    transition: opacity 150ms;
    opacity: 0.5;
  }
}

.wardrobe-image {
  @include flexBox(flex, center, center, column);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &--square {
    height: 200px;
    width: 200px;
  }

  &--portrait {
    height: 300px;
    width: 200px;
  }
}

.wardrobe-colour {
  @include flexBox(flex, center, center, column);
}

.wardrobe-image-loader {
  animation: pulse 1.5s ease-in infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  55% {
    opacity: 0.1;
  }
}
