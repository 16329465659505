.empty-state-container {
  background-color: $offWhite;
  width: 100vw;
  height: 100vh;
}

.empty-state-inner {
  background-color: $w;
  border-radius: 100%;
  width: 25vw;
  height: 25vw;
}

.empty-state-emoji {
  font-size: 7rem;
  line-height: 1;
}
