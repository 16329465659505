.outfit-card {
  width: 343px;
  height: 343px;
  padding: 10px;

  &-col {
    @include flexBox(flex, space-between, null, column);
    flex: 2;
    margin-right: 10px;
    height: 100%;

    &--small {
      flex: 1;
      margin-right: 0px;
    }
  }

  .outfit-card-block-placeholder {
    transition: border 300ms ease-out 150ms;
  }

  &:hover {
    .outfit-card-block-placeholder {
      transition: border 300ms ease-in;
      border: 2px dashed $tdPink2;
      opacity: 1;
    }
  }

  &-block {
    @include flexBox(flex, center, center, column);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 0px solid $tdBlue;

    &:hover {
      opacity: 0.5;
      transition: opacity 150ms ease-in;
    }

    &-placeholder {
      border: 2px dashed $w;

      &-icon {
        opacity: 0;
        transform: scale(0.3);
        transition: opacity 150ms ease-in-out, transform 300ms ease-in-out;
      }

      &:hover {
        border: 2px dashed $tdLightBlue !important;

        .outfit-card-block-placeholder-icon {
          opacity: 1;
          transform: scale(1);
          transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
        }
      }
    }

    &--selected {
      opacity: 1;
      border: 2px solid $tdBlue;

      &.outfit-card-block-placeholder {
        border: 2px dashed $tdBlue !important;
      }

      .outfit-card-block-placeholder-icon {
        opacity: 1;
        transform: scale(1);
      }
    }

    &--square {
      height: 121px;

      &-small {
        height: 56.6px;
      }
    }

    &--portrait {
      height: 192px;
    }
  }
}
