%tooltip {
  @include borderRadius(primary);
  backdrop-filter: blur(10px);
  background-color: rgba($tdBlk, 0.85);
  padding: 10px 12px;
}

.item-tooltip-wrapper {
  position: relative;
}

.item-tooltip {
  @extend %tooltip;
  @include center(x);
  @include fontSize(xs);
  @include borderRadius(primary);
  position: absolute;
  margin-top: 5px;
  color: $w;
  text-align: center;
}

.td-tooltip {
  & .tooltip-inner {
    @extend %tooltip;
  }

  &.tooltip .arrow {
    display: none;
  }
}
