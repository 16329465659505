.round-btn {
  @include boxShadow(secondary);
  padding: 15px;
  border-radius: 100%;
  transition: box-shadow 300ms ease;
  cursor: pointer;

  & img {
    display: block;
  }

  &:hover {
    @include boxShadow(primary);
  }

  &--small {
    min-width: 34px;
    min-height: 34px;
    padding: 7px;
  }
}

.btn-td {
  text-transform: uppercase;
}
